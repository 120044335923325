import '../../atoms/anchor/Anchor';
import '../../atoms/button/Button';
import '../../atoms/line-divider/LineDivider';
import '../../molecules/form-field/FormField';
import '../form-row/FormRow';
import HttpClient from '@naturehouse/nh-essentials/lib/requests/HttpClient';
import parseStringAsHtml from '@naturehouse/nh-essentials/lib/html/parser';
import HttpError from '@naturehouse/nh-essentials/lib/requests/HttpError';
import type { DefaultComponentType } from '../../../../src/utils/types';
import statusPanelTemplate from '../../molecules/status-panel/StatusPanel.html.njk';
import { StatusPanelVariant } from '../../molecules/status-panel/StatusPanel';
import AjaxForm, { type AjaxResponse } from '../form/AjaxForm';
import './LoginForm.pcss';

export enum LoginEvents {
    LOGIN_SUCCESS = 'login-success',
    LOGIN_FAILED = 'login-failed'
}

type LoginFormButtonType = DefaultComponentType & {
    label: string;
    href?: string;
};

type LoginFormCtaType = DefaultComponentType & {
    label: string;
    anchorText: string;
    href?: string;
    target?: string;
};

type LoginFormInputType = DefaultComponentType & {
    label: string;
    id: string;
    name: string;
    required?: boolean;
};

export type LoginResponseType = {
    isLandlord: boolean;
    personalInformation: {
        firstName?: string;
        lastName?: string;
        dateOfBirth?: string;
        email: string;
        phoneNumber?: string;
        street?: string;
        houseNumber?: string;
        zipCode?: string;
        place?: string;
        country?: string;
    };
};

export type LoginFormProps = DefaultComponentType & {
    action: string;
    method: 'GET' | 'POST';
    errorMessage: string;
    sitekey: string;
    email: LoginFormInputType;
    password: LoginFormInputType;
    rememberMe: LoginFormInputType;
    loginButton: LoginFormButtonType;
    googleButton: LoginFormButtonType;
    buttonDivider: LoginFormButtonType;
    passwordForget: LoginFormCtaType;
    register: LoginFormCtaType;
    appleButton?: LoginFormButtonType;
};

export default class LoginForm extends AjaxForm {
    #token: string | undefined;

    set token(value: string | undefined) {
        this.#token = value;
    }

    get errorMessage(): string {
        return this.getAttribute('error-message') ?? 'Something went wrong';
    }

    public async submitForm(event?: Event): Promise<boolean> {
        event?.preventDefault();

        if (this.submitting) return false;

        this.disableSubmitButtons();

        const action = this.getAttribute('action');
        if (action === null) {
            throw new Error('No action was set for this AjaxForm');
        }

        const formData = new FormData(this);
        let returnStatus = true;
        try {
            const data = {
                username: formData.get('username')?.toString() ?? '',
                password: formData.get('password')?.toString() ?? '',
                captcha: formData.get('captcha')?.toString() ?? '',
                rememberMe: formData.get('remember-me')?.toString() ?? '',
                _token: this.#token
            };

            const response = (await HttpClient.post(action, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            })) as unknown as Response;

            if (response instanceof HttpError) {
                throw new Error('Login failed');
            }

            this.onSuccess(response);
        } catch (error) {
            this.onError();
            returnStatus = false;
        }

        this.enableSubmitButtons();
        return returnStatus;
    }

    protected onSuccess(res: Response): void {
        this.#removeErrorStatusPanels();
        const response = res as unknown as AjaxResponse;

        this.dispatchEvent(
            new CustomEvent(LoginEvents.LOGIN_SUCCESS, {
                detail: this.#convertResponseToLoginResponseType(response)
            })
        );
    }

    protected onError(): void {
        this.#removeErrorStatusPanels();

        const errorMessage = parseStringAsHtml(
            statusPanelTemplate.render({
                variant: StatusPanelVariant.error,
                label: this.errorMessage,
                data: {
                    'data-role': 'error-status-panel'
                }
            }),
            'div'
        );

        this.insertBefore(errorMessage, this.firstChild);

        this.dispatchEvent(new Event(LoginEvents.LOGIN_FAILED));
    }

    #removeErrorStatusPanels(): void {
        this.querySelectorAll('[data-role="error-status-panel"]')?.forEach((panel) =>
            panel.remove()
        );
    }

    #convertResponseToLoginResponseType = (response: AjaxResponse): LoginResponseType => {
        const personalInformation = response.data?.personalInformation as Record<string, string>;

        return {
            isLandlord: response.data?.isLandlord?.toString() === 'true',
            personalInformation: {
                firstName: personalInformation?.firstName ?? undefined,
                lastName: personalInformation?.lastName ?? undefined,
                dateOfBirth: personalInformation?.dateOfBirth ?? undefined,
                email: personalInformation?.email ?? '',
                phoneNumber: personalInformation?.phoneNumber ?? undefined,
                street: personalInformation?.street ?? undefined,
                houseNumber: personalInformation?.houseNumber ?? undefined,
                zipCode: personalInformation?.zipCode ?? undefined,
                place: personalInformation?.place ?? undefined,
                country: personalInformation?.country ?? undefined
            }
        };
    };
}

if (!window.customElements.get('nh-login-form')) {
    window.customElements.define('nh-login-form', LoginForm, { extends: 'form' });
}
